import React, { useContext, useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import '../../index.scss'
import { Button, Form, Modal, Popover, Space, message as Message, message } from 'antd';
import { ProFormText } from "@ant-design/pro-form";
import { logout } from "../../context/authContext/AuthActions";
import { FirsrIcon, LastIcon, MiddleIcon, ProfileIcon, TaskIcon, UserIcon } from "../../icons";
import { LockOutlined } from '@ant-design/icons';
import { AuthContext } from "../../context/authContext/AuthContext";
import { updateUserPasswordByUserId } from "../../services/update";
import { handleErrors } from "../../utils/handleErrors";
import { REFRESH_TOKEN, TOKEN_NAME } from '../../constants';
import { changePasswordApi, logoutApi } from '../../apis/login';
import AccountModal from '../Account/Components/Modals/AccountModal';
import { getDepartmentList, getDetailAccount, getGroupUserList } from '../Account/Services/account.services';

export const MenuUser = ({ user, showModalTask }) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const modalAccount = useRef()
  const [listGroupUser, setListGroupUser] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [dataDetai, setDetail] = useState("");

  useEffect(() => {
    getDataGroupUser()
    getDataDepartment()
  }, []);

  const getDataGroupUser = async () => {
    const listResult = await getGroupUserList({});
    setListGroupUser(listResult?.data?.data || []);
  }

  const getDataDepartment = async () => {
    const listResult = await getDepartmentList({});
    setListDepartment(listResult?.data?.data || []);
  }

  const logoutUser = () => {
    Modal.info({
      title: 'Thông báo',
      content: (
        <div>
          <p>{'Thay đổi mật khẩu thành công.'}</p>
          <p>{'Vui lòng đăng nhập lại!'}</p>
        </div>
      ),
      onOk() {
        dispatch(logout());
      },
      okText: 'Chấp nhận',
    });
  }

  const handleEditInfo = async () => {
    console.log(localStorage.getItem("userId"))
    if (modalAccount.current) {
      modalAccount.current.open()
      if (true) {
        const { data } = await getDetailAccount(localStorage.getItem("userId"))
        modalAccount.current.setFieldsValue(data);
        setDetail(data);
      }
    }
    setVisiblePopover(false);
  }
  return <Popover
    placement="bottomLeft"
    visible={visiblePopover}
    onVisibleChange={() => setVisiblePopover(!visiblePopover)}
    // title={<ProfileIcon title={'Username'} label={user?.username} />}
    content={<div>
      <AccountModal
        // onClose={() => handleCancel()}
        listDepartment={listDepartment}
        listGroupUser={listGroupUser}
        actionRef={modalAccount}
        dataDetai={dataDetai}
      />
      <Modal
        title="Thay đổi mật khẩu"
        centered
        closable={false}
        visible={visible}
        width={340}
        footer={null}
        destroyOnClose
      >
        <Form
          layout={"vertical"}
          onFinish={async (values) => {
            try {
              // setLoading(true);
              console.log(values)
              const result = await changePasswordApi(values);
              if (result?.status === 200) {
                setVisible(false);
                localStorage.setItem(TOKEN_NAME, "")
                logoutUser();
              }
              else
                message.error(result?.data?.message)
              setLoading(false);
            } catch (e) {
              handleErrors(e, user.logoutUser, setLoading, 'Thay đổi mật khẩu KHÔNG thành công!');
              setHasError(true);
            }
          }}
        >
          {({ password }) => (<>
            {hasError && <p style={{ color: 'red' }}>{'Đã có lỗi xây ra, không thể thay đổi mật khẩu!'}</p>}
            <ProFormText.Password
              name="password"
              label={'Mật khẩu mới'}
              fieldProps={{
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Mật khẩu mới'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
              ]}
              onChange={() => setHasError(false)}
            />
            <ProFormText.Password
              name="confirmPassword"
              label={'Xác nhận mật khẩu'}
              fieldProps={{
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Xác nhận mật khẩu'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
                {
                  validator: (rule, value, callback) => {
                    if (value && value !== password) {
                      callback("Mật khẩu không đúng!");
                    } else {
                      callback();
                    }
                  }
                }
              ]}
              onChange={() => setHasError(false)}
            />
            <Space>
              <Button type="primary" loading={loading} danger onClick={() => setVisible(false)}>{'Hủy'}</Button>
              <Button type="primary" loading={loading} htmlType="submit">{'Gửi'}</Button>
            </Space></>)}
        </Form>
      </Modal>
      {/* <TaskIcon
        label="Nhắc nhở công việc"
        title="Nhắc nhở công việc"
        onClick={() => {
          showModalTask();
          setVisiblePopover(false);
        }}
      /> */}
      <FirsrIcon
        label={'Sửa thông tin'}
        title={'Sửa thông tin'}
        onClick={handleEditInfo}
      />
      <MiddleIcon
        icon={'password.svg'}
        label={'Đổi mật khẩu'}
        title={'Đổi mật khẩu'}
        onClick={() => {
          setVisible(true);
          setVisiblePopover(false);
        }}
      />
      <LastIcon
        icon={'signout.svg'}
        label={'Đăng xuất'}
        title={'Đăng xuất'}
        onClick={() => {
          logoutApi()
          localStorage.removeItem(TOKEN_NAME)
          localStorage.removeItem(REFRESH_TOKEN)
          dispatch(logout())
        }}
      />
    </div>} trigger="click">
    <UserIcon title={'Thông tin người dùng'} />
  </Popover>;
}
