import React, { useRef, useState } from 'react';
import 'antd/dist/antd.css';
import '../../index.scss'
import { MenuUser } from "../../components/common/MenuUser";
import ModalNotiTask from "../../components/Modal/ModalNotiTask";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  BarChartOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import logo from './../../icons/logo.svg'
import { Layout, Menu, Breadcrumb } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

//modal noti
const Container = ({ user, setHideMenu, hideMenu, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const taskRef = useRef();
  const items = [
    getItem('Quản lý tài liệu', '/', <FileOutlined />),
    getItem('Danh sách tài liệu', '/documents_list', <AppstoreOutlined />),
    // getItem('Tiêu chuẩn và câu hỏi', '/clinics', <BarChartOutlined />, [getItem('Danh sách phòng khám', '/clinic_list'), getItem('TIêu chuẩn lựa chọn', '/standard_accept'), getItem('Tiêu chuẩn loại bỏ', '/standard_cancel'), getItem('Bảng câu hỏi hiểu biết', '/questions')]),
    // getItem('Lịch sử hoạt động', '/activites', <PieChartOutlined />),
    // getItem('Quản lý tài liệu', '/document', <FileOutlined />, [getItem('Quản lý tài liệu', '/documents'), getItem('Danh sách tài liệu', '/documents_list')]),
    // getItem('Quản lý tài khoản', '/accounts', <TeamOutlined />, [getItem('Danh sách tài khoản', '/accounts_list'), getItem('Danh sách nhóm user', '/accounts_groups'), getItem('Danh sách phòng ban', '/accounts_departments')]),
    // getItem('Cài đặt website NTN', '/news', <AppstoreOutlined />, [getItem('Bài viết tin tức', '/news'), getItem('Tin quảng cáo', '/advertisements'), getItem('Hình ảnh hoạt động', '/images')]),
  ];
  return <Layout
    style={{
      minHeight: '100vh',
    }}
  >
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{
      // background: "#fff"
    }}>
      <div className="logo" style={{
        background: "#109cf1",
        height: "34px"
      }}>
        <img src={logo} style={{
          height: "32px",
          margin: "1px",
          width: "100%"
        }} />
      </div>
      <Menu theme="dark" selectedKeys={location.pathname} mode="inline" items={items} onSelect={e => history.push(e.key)} ></Menu>
    </Sider>
    <Layout className="site-layout">
      <div className={'container-page'} style={{
        background: "#fff"
      }}>
        <div className={'header'}>
          <div className="icon-menu">
            {/* <div onClick={() => setHideMenu(!hideMenu)}>
              {hideMenu ? <MenuUnfoldOutlined title="Mở menu" className="icon" /> :
                <MenuFoldOutlined title="Đóng menu" className="icon" />}
            </div>
            <div className="logo">
              <img src={logo} height={32} />
            </div> */}
          </div>
          <MenuUser user={user} showModalTask={() => {
            if (taskRef.current)
              taskRef.current.open(true)
          }} />
          {/* <ModalNotiTask actionRef={taskRef} /> */}
        </div>

        <div className={'content-page'} style={{
          margin: "0px 16px"
        }}>{children}</div>
      </div>
    </Layout>
  </Layout>
  return (<div className={'container-page'}>
    <div className={'header'}>
      <div className="icon-menu">
        <div onClick={() => setHideMenu(!hideMenu)}>
          {hideMenu ? <MenuUnfoldOutlined title="Mở menu" className="icon" /> :
            <MenuFoldOutlined title="Đóng menu" className="icon" />}
        </div>
        <div className="logo">
          <img src={logo} height={24} />
        </div>
      </div>
      <MenuUser user={user} />
      <ModalNotiTask />
    </div>

    <div className={'content-page'}>{children}</div>
  </div>
  );
};

export default Container;
